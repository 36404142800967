import { SearchLocationState } from "@/store/actions";
import {
  BookingConfirmation_S,
  CabinPanelSummaryResponse,
  CabinPanelSummary_S,
  Category_S,
  Customer,
  QueryCabinAvailabilityArgs,
} from "@generated/types";
import moment from "moment";
import TagManager from "react-gtm-module";
import { LocationsDropdownItem } from "src/interfaces/bookingForm";
import { Category } from "src/interfaces/extrasForReservation";
import { Guests } from "src/interfaces/guests";
import { getHashHexCode } from "./common";
import { getAbsoluteURL } from "src/utils";
import { BedroomOption } from "@components/SemanticTheme/CabinOfferCard";

const addObjectIndex = (obj: CustomType) => {
  obj?.items?.forEach((item: any, index: number) => {
    item.index = index + 1;
  });
  return obj;
};

type CustomType = PaymentInfo | Purchase;

export enum EventTypes {
  pageView = "pageView",
  Search = "search",
  viewItemList = "view_item_list",
  selectItem = "select_item",
  viewItem = "view_item",
  purchase = "purchase",
  addPaymentInfo = "add_payment_info",
  viewCart = "view_cart",
  beginCheckout = "begin_checkout",
  playCabinTour = "playCabinTourViaSearchResults",
  closeCabinTour = "closeCabinTourViaSearchResults",
  signUp = "new_subscriber",
}

enum CabinFilterTypes {
  cabinType = "Cabin types",
  cabinFeature = "Cabin features",
}

type CategoryList = {
  item_category: string;
  item_category2: string;
  item_category3: string;
  item_category4: string;
  item_category5: string;
  item_category6: string;
  item_category7: string;
  item_category8: string;
  item_category9?: string | number;
  item_category10: string;
  item_category11?: string;
};

type GuestsList = {
  item_list_id?: string;
  item_list_name?: string;
  item_list_guests: number;
  item_list_adults: number;
  item_list_children: number;
  item_list_infants: number;
};

type Product = {
  item_id: string;
  item_name: string;
  index?: number;
  variant: string | number;
  price: number;
  quantity: number;
  coupon?: string;
  discount?: number;
};

type CabinSearchResults = Product & GuestsList & CategoryList;

type LocationSearch = {
  search_keywords: string;
  search_dates: string;
  search_guests: number;
  search_adults: number;
  search_children: number;
  search_infants: number;
  search_pets: number;
  search_rooms: number;
  search_nights: number;
  search_filters: string;
  search_sort: string;
  search_count: number;
  _clear?: boolean;
};

type CabinSearch = {
  item_list_id: string;
  item_list_name: string;
  item_list_dates: string;
  item_list_nights: number;
  search_guests: number;
  search_adults: number;
  search_children: number;
  search_infants: number;
  item_list_pets: number;
  item_list_dda: number;
  item_list_rooms: number;
  item_list_filters_types: string;
  item_list_filters_features: string;
  item_list_sort: string;
  item_list_count: number;
  items: CabinSearchResults[];
  _clear?: boolean;
};

type SelectedCabin = {
  currency: string;
  value: number;
  item_list_id: string;
  item_list_name: string;
  search_guests: number;
  search_adults: number;
  search_children: number;
  search_infants: number;
  items: CabinSearchResults[];
  _clear?: boolean;
};

type ConfirmedCabin = {
  fh_transaction_id: string;
  currency: string;
  value: number;
  search_guests: number;
  search_adults: number;
  search_children: number;
  search_infants: number;
  items: CabinSearchResults[];
  _clear?: boolean;
};

type PaymentInfo = {
  fh_transaction_id: string;
  currency: string;
  value: number;
  coupon: string;
  payment_type: string;
  search_guests: number;
  search_adults: number;
  search_children: number;
  search_infants: number;
  items: CabinSearchResults[];
  _clear?: boolean;
};

type PurchaseItems = Product & GuestsList & CategoryList;

type Purchase = {
  transaction_id: string;
  currency: string;
  value: number;
  tax: number;
  coupon: string;
  payment_type: string;
  first_name: string;
  last_name: string;
  email_address: string;
  net_cabin_only_price: number;
  search_guests: number;
  search_adults: number;
  search_children: number;
  search_infants: number;
  items: PurchaseItems[];
  _clear?: boolean;
};

type ViewCart = {
  fh_transaction_id: string;
  currency: string;
  value: number;
  search_guests: number;
  search_adults: number;
  search_children: number;
  search_infants: number;
  items: CabinSearchResults[];
  _clear?: boolean;
};

type BeginCheckOut = ViewCart;

type PlayCabinTourInfo = {
  cabinType: string;
  location: string;
  _clear?: boolean;
};

type CloseCabinTourInfo = {
  cabinType: string;
  location: string;
  watchTimeInSeconds: number;
  _clear?: boolean;
};

type SignUpInfo = {
  formLocation: string;
  name: string;
  email: string;
  _clear?: boolean;
};

type DataLayer =
  | LocationSearch
  | CabinSearch
  | SelectedCabin
  | ConfirmedCabin
  | Purchase
  | PaymentInfo
  | ViewCart
  | BeginCheckOut
  | PlayCabinTourInfo
  | CloseCabinTourInfo
  | SignUpInfo;

export const postToDataLayer = (event: string, data: DataLayer) => {
  const eventObj = {
    event,
    ...data,
  };

  TagManager.dataLayer({
    dataLayer: eventObj,
  });
};

const findMatchingTagNamesByIds = (
  availableFilters: Category[],
  activeFilterIds: string[],
  activeFilterCategory?: string,
) => {
  const matchingTagNames = [];

  availableFilters?.forEach((category) => {
    if (!activeFilterCategory || category.name === activeFilterCategory) {
      category?.tags?.forEach((tag) => {
        if (activeFilterIds?.includes(tag?.id)) {
          matchingTagNames.push(tag?.name);
        }
      });
    } else if (
      activeFilterCategory === CabinFilterTypes.cabinFeature &&
      category?.name !== CabinFilterTypes.cabinType
    ) {
      category?.tags?.forEach((tag) => {
        if (activeFilterIds?.includes(tag?.id)) {
          matchingTagNames.push(tag?.name);
        }
      });
    }
  });

  return matchingTagNames;
};

const getGALocationSearchData = (
  locationSearch: SearchLocationState,
  locations: LocationsDropdownItem[],
) => {
  const {
    data,
    locationSearchData,
    sortMethod,
    availableFilters,
    activeFilters,
  } = locationSearch;
  const selectedLocations = data?.locations
    ?.map((location) => location?.locationName)
    ?.join(", ");
  const selectedLocationIds = locationSearchData?.locationIds?.length;
  const isAllLocationsSelected = locations?.length === selectedLocationIds;
  const startDate = moment(locationSearchData?.startDate);
  const endDate = moment(locationSearchData?.endDate);
  const duration = endDate?.diff(startDate, "days");
  const appliedFilters = findMatchingTagNamesByIds(
    availableFilters,
    activeFilters,
  );

  const searchDetails: LocationSearch = {
    search_keywords: `Searched for: ${
      isAllLocationsSelected ? "All locations" : selectedLocations
    }, ${duration} nights`,
    search_dates: `${locationSearchData?.startDate} | ${locationSearchData?.endDate}`,
    search_guests: [
      locationSearchData?.numberOfAdults,
      locationSearchData?.numberOfChildren,
      locationSearchData?.numberOfInfants,
    ].reduce((a, b) => a + (b || 0), 0),
    search_adults: locationSearchData?.numberOfAdults,
    search_children: locationSearchData?.numberOfChildren,
    search_infants: locationSearchData?.numberOfInfants,
    search_pets: locationSearchData?.numberOfPets,
    search_rooms: locationSearchData?.numberOfBedrooms,
    search_nights: duration,
    search_filters: appliedFilters?.join(" | "),
    search_sort: sortMethod || "",
    search_count: selectedLocationIds,
  };

  return searchDetails;
};

const getGACabinSearchData = (
  cabinSearchData: QueryCabinAvailabilityArgs,
  cabinPanels: CabinPanelSummary_S[],
  guests?: Guests,
  availableFilters?: Category_S[],
  eventType?: EventTypes,
  selectedBedroom?: BedroomOption,
  selectedLocationData?: LocationsDropdownItem,
) => {
  const locationName = cabinPanels?.[0]?.locationName;
  const startDate = cabinSearchData?.request?.startDate;
  const endDate = cabinSearchData?.request?.endDate;
  const momentStartDate = moment(cabinSearchData?.request?.startDate);
  const momentEndDate = moment(cabinSearchData?.request?.endDate);
  const duration =
    momentStartDate && momentEndDate
      ? (momentEndDate?.diff(momentStartDate, "days") as number)
      : 0;
  const listId = locationName
    ? `${locationName} ${duration} nights`
        ?.replaceAll(" ", "_")
        ?.toLocaleLowerCase()
    : `${selectedLocationData.name} ${duration} nights`
        ?.replaceAll(" ", "_")
        ?.toLocaleLowerCase();
  const listName = locationName
    ? `${locationName}, ${duration} nights`
    : `${selectedLocationData.name}, ${duration} nights`;
  const adults = +guests?.adults ?? 0;
  const childrens = +guests?.children ?? 0;
  const infants = +guests?.infants ?? 0;
  const totalGuests = adults + childrens + infants;
  const pets = cabinSearchData?.request?.numberOfPets ?? 0;
  const dda = cabinSearchData?.request?.disabledAccess ? 1 : 0;
  const bedRooms = cabinSearchData?.request?.numberOfBedrooms ?? 0;
  const selectedFilterIds = cabinSearchData?.selectedTagIds;
  const sortMethod = cabinSearchData?.request?.sortMethod;
  const appliedFilterCabinTypes = findMatchingTagNamesByIds(
    availableFilters,
    selectedFilterIds,
    CabinFilterTypes.cabinType,
  );
  const appliedFeatureFilters = findMatchingTagNamesByIds(
    availableFilters,
    selectedFilterIds,
    CabinFilterTypes.cabinFeature,
  );

  const cabinList = cabinPanels?.map((item, index) => {
    const totalCabins = [
      ...(item.petFreeBedroomOptions || []),
      ...(item.petFriendlyBedroomOptions || []),
    ]
      ?.filter((cabin) => cabin?.isAvailable && cabin?.numberOfCabins > 0)
      ?.sort((a, b) => a.numberOfBedrooms - b.numberOfBedrooms);

    const finalCabins = totalCabins?.map((cabin, i) => {
      const cabinName = `${item?.locationName} ${item?.cabinTypeName} ${
        cabin?.numberOfBedrooms
      } bed ${cabin?.isPetFriendly ? "Pet Friendly" : "Pet Free"} ${
        item?.isDDA ? "DDA" : ""
      }`;
      const cabinPrice =
        (cabin?.postDiscountPrice || 0) - (cabin?.postDiscountPetPrice || 0);
      return {
        item_id: cabin?.productId,
        item_name: cabinName,
        index: i + 1,
        variant: cabin?.numberOfBedrooms,
        price: parseFloat(cabinPrice?.toFixed(2)),
        quantity: 1,
        item_category: "Cabin",
        item_category2: item?.locationName,
        item_category3: item?.locationId,
        item_category4: item?.cabinTypeName,
        item_category5: item?.cabinTypeId,
        item_category6: cabin?.isPetFriendly ? "Pet Friendly" : "Pet Free",
        item_category7: item?.isDDA ? "Y" : "N",
        item_category8: moment(item?.startDate).format("YYYY-MM-DD"),
        item_category9: item?.duration,
        item_category10: getAbsoluteURL(item?.primaryCallToActionRef),
        item_category11: item?.images?.[0]?.url,
        item_list_id: listId,
        item_list_name: listName,
        item_list_guests: totalGuests,
        item_list_adults: adults,
        item_list_children: childrens,
        item_list_infants: infants,
      };
    });
    return finalCabins;
  });
  const finalCabinsList = cabinList?.flatMap((item) => item) ?? [];

  const updatedIndexCabinsList = finalCabinsList?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const selectCabinOption = finalCabinsList?.filter((cabin) => {
    const bedroom = cabin?.variant;
    const isPetFriendly = cabin?.item_category6 === "Pet Friendly";
    const selectedCabin =
      bedroom === selectedBedroom?.numberOfBedrooms &&
      isPetFriendly === selectedBedroom?.isPetFriendly;
    return selectedCabin;
  });

  const searchDetails: CabinSearch = {
    item_list_id: listId,
    item_list_name: listName,
    item_list_dates: `${startDate} | ${endDate}`,
    item_list_nights: duration,
    search_guests: totalGuests,
    search_adults: adults,
    search_children: childrens,
    search_infants: infants,
    item_list_pets: pets,
    item_list_dda: dda,
    item_list_rooms: bedRooms,
    item_list_filters_types: appliedFilterCabinTypes?.join(" | "),
    item_list_filters_features: appliedFeatureFilters?.join(" | "),
    item_list_sort: sortMethod,
    item_list_count: finalCabinsList?.length,
    items: updatedIndexCabinsList,
  };

  const selectedCabin: SelectedCabin = {
    currency: "GBP",
    value: parseFloat(selectCabinOption?.[0]?.price?.toFixed(2)),
    item_list_id: listId,
    item_list_name: listName,
    search_guests: totalGuests,
    search_adults: adults,
    search_children: childrens,
    search_infants: infants,
    items: selectCabinOption,
  };

  return eventType === EventTypes?.selectItem ? selectedCabin : searchDetails;
};

const getGAPurchaseData = (
  confirmation: BookingConfirmation_S,
  userData: Customer,
  guests: Guests,
) => {
  const { cabinReservations } = confirmation;
  const adults = +guests?.adults ?? 0;
  const childrens = +guests?.children ?? 0;
  const infants = +guests?.infants ?? 0;
  const totalGuests = adults + childrens + infants;
  const hashedFirstName = getHashHexCode(userData?.forenames);
  const hashedSurname = getHashHexCode(userData?.surname);
  const hashedEmail = getHashHexCode(userData?.email);

  const purchaseData: Purchase = {
    transaction_id: confirmation?.bookingReference,
    currency: "GBP",
    value: parseFloat(confirmation?.totalPrice?.toFixed(2)),
    tax: parseFloat(confirmation?.tax?.toFixed(2)),
    coupon: "",
    payment_type: confirmation?.paymentType || "",
    first_name: hashedFirstName,
    last_name: hashedSurname,
    email_address: hashedEmail,
    net_cabin_only_price: 0,
    search_guests: totalGuests,
    search_adults: adults,
    search_children: childrens,
    search_infants: infants,
    items: [],
  };

  if (cabinReservations?.length > 0) {
    cabinReservations.map((cabin, index) => {
      if (!!cabin) {
        const duration = cabin?.noOfNights;
        const locationName = cabin?.locationName;
        const isPetFriendlyOrFree = cabin?.petFriendly
          ? "Pet Friendly"
          : "Pet Free";
        const isDDA = cabin?.disabledAccess ? "Y" : "N";
        const cabinDiscount =
          (cabin?.preDiscountCabinPrice || 0) - (cabin?.discountPrice || 0);
        // Format  -> [Location Name] [Cabin Type] [Cabin Beds] bed [Cabin Pet Friendly] [Cabin DDA ? "DDA" : ""]
        // "Location Name, Cabin Type, Cabin Beds, Cabin Pet Friendly, Cabin DDA"
        const cabinName = `${cabin?.locationName} ${cabin?.cabinTypeName} ${
          cabin?.noOfBedrooms
        } bed ${isPetFriendlyOrFree} ${cabin?.disabledAccess ? "DDA" : ""}`;
        purchaseData.net_cabin_only_price = parseFloat(
          cabin?.netCabinSellingPrice?.toFixed(2),
        );
        purchaseData.coupon = cabin?.discountCode?.toUpperCase() || "";

        purchaseData?.items.push({
          item_id: cabin?.productID,
          item_name: cabinName,
          variant: cabin?.noOfBedrooms,
          price:
            !!cabin?.discountCode && cabin?.discountPrice > 0
              ? parseFloat(cabin?.discountPrice?.toFixed(2))
              : parseFloat(cabin?.preDiscountCabinPrice?.toFixed(2)),
          quantity: 1,
          coupon: cabin?.discountPrice === 0 ? "" : cabin?.discountCode || "",
          discount:
            cabinDiscount > 0 && cabin?.discountPrice !== 0
              ? parseFloat(cabinDiscount?.toFixed(2))
              : 0,
          item_category: "Cabin",
          item_category2: locationName,
          item_category3: cabin?.locationId,
          item_category4: cabin?.cabinTypeName,
          item_category5: cabin?.cabinTypeId,
          item_category6: isPetFriendlyOrFree,
          item_category7: isDDA,
          item_category8: moment(cabin?.reservationStartDate).format(
            "YYYY-MM-DD",
          ),
          item_category9: duration,
          item_category10: getAbsoluteURL(cabin?.cabinDetailsCTALink) || "",
          item_category11: cabin?.cabinTypeImages?.[0]?.url || "",
          // Format -> Location Name, Duration -> "delamere_forest_4_nights"
          item_list_id: `${locationName} ${duration} nights`
            ?.replaceAll(" ", "_")
            ?.toLocaleLowerCase(),
          // Format -> Location Name, Duration -> "Delamere Forest, 4 nights"
          item_list_name: `${locationName}, ${duration} nights`,
          item_list_guests: totalGuests,
          item_list_adults: adults,
          item_list_children: childrens,
          item_list_infants: infants,
        });

        cabin?.reservationExtras?.map((extra) => {
          if (extra) {
            extra?.reservationExtraOptions?.map((extraOption) => {
              if (!cabin?.discountCode && !!extraOption?.extraPromotionCode)
                purchaseData.coupon =
                  extraOption?.extraPromotionCode?.toUpperCase() || "";
              if (!!extraOption) {
                purchaseData?.items.push({
                  item_id: extra?.extraCode,
                  item_name: extra?.extraName,
                  variant: extraOption?.extraOptionName,
                  price: parseFloat(extraOption?.sellingPrice?.toFixed(2)),
                  quantity: extraOption?.quantity,
                  coupon: extraOption?.extraPromotionCode || "",
                  discount: extraOption?.discountAmount || 0,
                  item_category: "Extra",
                  item_category2: extra?.extraCategory || "",
                  item_category3: extra?.selectedDate || "",
                  item_category4: extraOption?.aMPM || "",
                  item_category5: extra?.extraId,
                  item_category6: extraOption?.extraOptionId,
                  item_category7: extra?.extraCategoryId,
                  item_category8: "Pre Booking",
                  item_category9:
                    getAbsoluteURL(extra?.extraDetailsPageLink) || "",
                  item_category10: extra?.images?.[0]?.url || "",
                  item_list_guests: totalGuests,
                  item_list_adults: adults,
                  item_list_children: childrens,
                  item_list_infants: infants,
                });
              }
            });
          }
        });
      }
    });
  }

  return purchaseData;
};

const getGAViewItemData = (
  confirmation: BookingConfirmation_S,
  guests?: Guests,
) => {
  const { cabinReservations } = confirmation;
  const totalGuests = guests?.adults + guests?.children + guests?.infants;
  const confirmedCabin = {
    fh_transaction_id: confirmation?.bookingReference,
    currency: "GBP",
    value: parseFloat(confirmation?.totalPrice?.toFixed(2)),
    search_guests: totalGuests,
    search_adults: +guests?.adults ?? 0,
    search_children: +guests?.children ?? 0,
    search_infants: +guests?.infants ?? 0,
    items: [],
    _clear: true,
  };
  if (cabinReservations?.length > 0) {
    cabinReservations.map((cabin, index) => {
      if (!!cabin) {
        const duration = cabin?.noOfNights;
        const locationName = cabin?.locationName;
        const isPetFriendlyOrFree = cabin?.petFriendly
          ? "Pet Friendly"
          : "Pet Free";
        const isDDA = cabin?.disabledAccess ? "Y" : "N";
        // Format  -> [Location Name] [Cabin Type] [Cabin Beds] bed [Cabin Pet Friendly] [Cabin DDA ? "DDA" : ""]
        // "Location Name, Cabin Type, Cabin Beds, Cabin Pet Friendly, Cabin DDA"
        const cabinName = `${cabin?.locationName} ${cabin?.cabinTypeName} ${
          cabin?.noOfBedrooms
        } bed ${isPetFriendlyOrFree} ${cabin?.disabledAccess ? "DDA" : ""}`;

        confirmedCabin?.items.push({
          item_id: cabin?.productID,
          item_name: cabinName,
          variant: cabin?.noOfBedrooms,
          price: parseFloat(cabin?.sellingPrice?.toFixed(2)),
          quantity: 1,
          item_category: "Cabin",
          item_category2: locationName,
          item_category3: cabin?.locationId,
          item_category4: cabin?.cabinTypeName,
          item_category5: cabin?.cabinTypeId,
          item_category6: isPetFriendlyOrFree,
          item_category7: isDDA,
          item_category8: moment(cabin?.reservationStartDate).format(
            "YYYY-MM-DD",
          ),
          item_category9: duration,
          item_category10: getAbsoluteURL(cabin?.cabinDetailsCTALink) || "",
          item_category11: cabin?.cabinTypeImages?.[0]?.url || "",
          // Format -> Location Name, Duration -> "delamere_forest_4_nights"
          item_list_id: `${locationName} ${duration} nights`
            ?.replaceAll(" ", "_")
            ?.toLocaleLowerCase(),
          // Format -> Location Name, Duration -> "Delamere Forest, 4 nights"
          item_list_name: `${locationName}, ${duration} nights`,
          item_list_guests: totalGuests,
          item_list_adults: guests?.adults ?? 0,
          item_list_children: guests?.children ?? 0,
          item_list_infants: guests?.infants ?? 0,
        });
      }
    });
  }

  return confirmedCabin;
};

const getGAAddPaymentInfoData = (
  confirmation: BookingConfirmation_S,
  guests?: Guests,
  paymentType?: string,
) => {
  const { cabinReservations } = confirmation;
  const adults = +guests?.adults ?? 0;
  const childrens = +guests?.children ?? 0;
  const infants = +guests?.infants ?? 0;
  const totalGuests = adults + childrens + infants;
  const addPaymentInfo: PaymentInfo = {
    fh_transaction_id: confirmation?.bookingReference,
    currency: "GBP",
    value: confirmation?.balance,
    coupon: "",
    payment_type: paymentType || "",
    search_guests: totalGuests,
    search_adults: +guests?.adults ?? 0,
    search_children: +guests?.children ?? 0,
    search_infants: +guests?.infants ?? 0,
    items: [],
  };
  if (cabinReservations?.length > 0) {
    cabinReservations.map((cabin, index) => {
      if (!!cabin) {
        const duration = cabin?.noOfNights;
        const locationName = cabin?.locationName;
        const isPetFriendlyOrFree = cabin?.petFriendly
          ? "Pet Friendly"
          : "Pet Free";
        const isDDA = cabin?.disabledAccess ? "Y" : "N";
        const cabinDiscount =
          (cabin?.preDiscountCabinPrice || 0) - (cabin?.discountPrice || 0);
        // Format  -> [Location Name] [Cabin Type] [Cabin Beds] bed [Cabin Pet Friendly] [Cabin DDA ? "DDA" : ""]
        // "Location Name, Cabin Type, Cabin Beds, Cabin Pet Friendly, Cabin DDA"
        const cabinName = `${cabin?.locationName} ${cabin?.cabinTypeName} ${
          cabin?.noOfBedrooms
        } bed ${isPetFriendlyOrFree} ${cabin?.disabledAccess ? "DDA" : ""}`;
        addPaymentInfo.coupon = cabin?.discountCode?.toUpperCase() || "";
        //For Cabins Object
        addPaymentInfo?.items.push({
          item_id: cabin?.productID,
          item_name: cabinName,
          variant: cabin?.noOfBedrooms,
          price: parseFloat(cabin?.sellingPrice?.toFixed(2)),
          quantity: 1,
          coupon: cabin?.discountPrice === 0 ? "" : cabin?.discountCode || "",
          discount:
            cabinDiscount > 0 && cabin?.discountPrice !== 0
              ? parseFloat(cabinDiscount?.toFixed(2))
              : 0,
          item_category: "Cabin",
          item_category2: locationName,
          item_category3: cabin?.locationId,
          item_category4: cabin?.cabinTypeName,
          item_category5: cabin?.cabinTypeId,
          item_category6: isPetFriendlyOrFree,
          item_category7: isDDA,
          item_category8: moment(cabin?.reservationStartDate).format(
            "YYYY-MM-DD",
          ),
          item_category9: duration,
          item_category10: getAbsoluteURL(cabin?.cabinDetailsCTALink) || "",
          item_category11: cabin?.cabinTypeImages?.[0]?.url || "",
          // Format -> Location Name, Duration -> "delamere_forest_4_nights"
          item_list_id: `${locationName} ${duration} nights`
            ?.replaceAll(" ", "_")
            ?.toLocaleLowerCase(),
          // Format -> Location Name, Duration -> "Delamere Forest, 4 nights"
          item_list_name: `${locationName}, ${duration} nights`,
          item_list_guests: totalGuests,
          item_list_adults: guests?.adults ?? 0,
          item_list_children: guests?.children ?? 0,
          item_list_infants: guests?.infants ?? 0,
        });
        cabin?.reservationExtras?.map((extra) => {
          if (extra) {
            extra?.reservationExtraOptions?.map((extraOption) => {
              if (!cabin?.discountCode && !!extraOption?.extraPromotionCode)
                addPaymentInfo.coupon =
                  extraOption?.extraPromotionCode?.toUpperCase() || "";
              if (!!extraOption) {
                addPaymentInfo?.items.push({
                  item_id: extra?.extraCode,
                  item_name: extra?.extraName,
                  variant: extraOption?.extraOptionName,
                  price: parseFloat(extraOption?.sellingPrice?.toFixed(2)),
                  quantity: extraOption?.quantity,
                  coupon: extraOption?.extraPromotionCode || "",
                  discount: extraOption?.discountAmount || 0,
                  item_category: "Extra",
                  item_category2: extra?.extraCategory || "",
                  item_category3: extra?.selectedDate || "",
                  item_category4: extra?.aMPM || "",
                  item_category5: extra?.extraId,
                  item_category6: extraOption?.extraOptionId,
                  item_category7: extra?.extraCategoryId,
                  item_category8: "Pre Booking",
                  item_category10:
                    getAbsoluteURL(extra?.extraDetailsPageLink) || "",
                  item_category11: extra?.images?.[0]?.url || "",
                  item_list_guests: totalGuests,
                  item_list_adults: adults,
                  item_list_children: childrens,
                  item_list_infants: infants,
                });
              }
            });
          }
        });
      }
    });
  }

  return addPaymentInfo;
};

const getGAViewCart = (
  confirmation: BookingConfirmation_S,
  guests?: Guests,
) => {
  const viewCartData = addObjectIndex(
    getGAAddPaymentInfoData(confirmation, guests),
  );
  if (viewCartData.hasOwnProperty("coupon")) delete viewCartData.coupon;
  if (viewCartData.hasOwnProperty("payment_type"))
    delete viewCartData.payment_type;
  return viewCartData as ViewCart;
};

export const postGAViewCart = (
  confirmation: BookingConfirmation_S,
  guests?: Guests,
) => {
  const viewCartInfo = getGAViewCart(confirmation, guests);
  viewCartInfo._clear = true;
  if (!!viewCartInfo) {
    postToDataLayer(EventTypes?.viewCart, viewCartInfo);
  }
};

const getGABeginCheckout = (
  confirmation: BookingConfirmation_S,
  guests?: Guests,
) => {
  const beginCheckoutData = addObjectIndex(
    getGAAddPaymentInfoData(confirmation, guests),
  );
  if (beginCheckoutData.hasOwnProperty("coupon"))
    delete beginCheckoutData.coupon;
  if (beginCheckoutData.hasOwnProperty("payment_type"))
    delete beginCheckoutData.payment_type;
  return beginCheckoutData as BeginCheckOut;
};

export const postGABeginCheckout = (
  confirmation: BookingConfirmation_S,
  guests?: Guests,
) => {
  const beginCheckoutInfo = getGABeginCheckout(confirmation, guests);
  beginCheckoutInfo._clear = true;
  if (!!beginCheckoutInfo) {
    postToDataLayer(EventTypes?.beginCheckout, beginCheckoutInfo);
  }
};

export const postGALocationSearchResults = (
  locationSearchData: SearchLocationState,
  locations: LocationsDropdownItem[],
) => {
  const locationSearchInfo = getGALocationSearchData(
    locationSearchData,
    locations,
  );
  locationSearchInfo._clear = true;
  if (!!locationSearchData) {
    postToDataLayer(EventTypes?.Search, locationSearchInfo);
  }
};

export const postGACabinSearchResults = (
  cabinSearchData: QueryCabinAvailabilityArgs,
  cabinData: CabinPanelSummaryResponse,
  guests?: Guests,
  selectedLocationData?: LocationsDropdownItem,
) => {
  const { cabinPanels: cabinSearchResults, availableFilters } = cabinData;

  const cabinSearchInfo = getGACabinSearchData(
    cabinSearchData,
    cabinSearchResults,
    guests,
    availableFilters,
    undefined,
    undefined,
    selectedLocationData,
  );
  cabinSearchInfo._clear = true;
  if (!!cabinSearchInfo) {
    postToDataLayer(EventTypes?.viewItemList, cabinSearchInfo);
  }
};

export const postGACabinSelectItem = (
  cabinSearchData: QueryCabinAvailabilityArgs,
  selectedCabin: CabinPanelSummary_S[],
  guests?: Guests,
  selectedBedroom?: BedroomOption,
) => {
  const cabinSelectItem = getGACabinSearchData(
    cabinSearchData,
    selectedCabin,
    guests,
    undefined,
    EventTypes?.selectItem,
    selectedBedroom,
  );
  cabinSelectItem._clear = true;
  if (!!cabinSelectItem) {
    postToDataLayer(EventTypes?.selectItem, cabinSelectItem);
  }
};

export const postGAViewItem = (
  confirmation: BookingConfirmation_S,
  guests?: Guests,
) => {
  const viewItem = getGAViewItemData(confirmation, guests);
  viewItem._clear = true;
  if (!!viewItem) {
    postToDataLayer(EventTypes?.viewItem, viewItem);
  }
};

export const postGAPurchase = (
  confirmation: BookingConfirmation_S,
  userData: Customer,
  guests: Guests,
) => {
  const purchaseInfo = addObjectIndex(
    getGAPurchaseData(confirmation, userData, guests),
  );
  purchaseInfo._clear = true;
  if (!!purchaseInfo) {
    postToDataLayer(EventTypes?.purchase, purchaseInfo);
  }
};
export const postGAAddPaymentInfo = (
  confirmation: BookingConfirmation_S,
  guests?: Guests,
  paymentType?: string,
) => {
  const add_payment_info = addObjectIndex(
    getGAAddPaymentInfoData(confirmation, guests, paymentType),
  );
  add_payment_info._clear = true;
  if (!!add_payment_info) {
    postToDataLayer(EventTypes?.addPaymentInfo, add_payment_info);
  }
};

export const postPlayCabinTourViaSearchResults = (
  cabinInfo: PlayCabinTourInfo,
) => {
  const playCabinTourInfo: PlayCabinTourInfo = {
    cabinType: cabinInfo?.cabinType,
    location: cabinInfo?.location,
    _clear: true,
  };
  playCabinTourInfo._clear = true;
  if (!!playCabinTourInfo) {
    postToDataLayer(EventTypes?.playCabinTour, playCabinTourInfo);
  }
};

export const postCloseCabinTourViaSearchResults = (
  cabinInfo: CloseCabinTourInfo,
) => {
  const closeCabinTourInfo: CloseCabinTourInfo = {
    cabinType: cabinInfo?.cabinType,
    location: cabinInfo?.location,
    watchTimeInSeconds: cabinInfo?.watchTimeInSeconds,
    _clear: true,
  };
  closeCabinTourInfo._clear = true;
  if (!!closeCabinTourInfo) {
    postToDataLayer(EventTypes?.closeCabinTour, closeCabinTourInfo);
  }
};

export const postSignUpFromHeader = (subscriberInfo: SignUpInfo) => {
  const signUpInfo: SignUpInfo = {
    formLocation: subscriberInfo?.formLocation,
    name: subscriberInfo?.name,
    email: subscriberInfo?.email,
    _clear: true,
  };
  if (!!signUpInfo) {
    postToDataLayer(EventTypes?.signUp, signUpInfo);
  }
};
